<template>
	<div class="archive-view">
		<section class="year" v-for="(year, key) in archives" :key="key">
			<h2>{{ year.year }}</h2>
			<div class="seasons">
				<router-link
					:to="{ name: 'season', params: { season: season.season, year: year.year } }"
					class="season"
					v-for="(season, index, key) in year.seasons"
					:key="key"
					:style="{ '--season-color': season.color }"
				>
					<img
						class="season-image"
						:src="season.image"
						:class="{ loaded: imagesLoaded[season.image] }"
						@load="onImageLoad($event, season.image);"
					/>
					<div class="name">{{ season.season | capitalize }}</div>
					<div class="total">{{ season.total }} Anime</div>
				</router-link>
			</div>
		</section>
	</div>
</template>

<script>
import archiveData from "@/assets/archive.json";

export default {
	data() {
		return {
			archives: archiveData,
			imagesLoaded: {}
		};
	},
	methods: {
		onImageLoad(event, image) {
			this.$set(this.imagesLoaded, image, true);
		}
	},
	metaInfo() {
		const title = `AniChart: Seasonal Anime Archive`;
		const description = `Take a look at past seasonal anime shows and movies in the AniChart archives. Find out what were the most popular and top-rated anime of each season.`;
		return {
			title,
			meta: [
				{
					name: "description",
					content: description
				},
				{
					property: "og:title",
					content: title
				},
				{
					property: "og:description",
					content: description
				},
				{
					property: "og:url",
					content: `https://anichart.net${this.$route.path}`
				},
				{
					property: "twitter:title",
					content: title
				},
				{
					property: "twitter:description",
					content: description
				}
			]
		};
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.archive-view {
	max-width: 1000px;
	margin: 0 auto;
	position: relative;

	@media (max-width: $size-tablet) {
		margin: 0 20px;
	}
}

.year {
	backface-visibility: hidden;
	margin-bottom: 50px;
	transform: translateZ(0);

	@media (min-width: $size-tablet) {
		&:first-of-type h2 {
			color: color(white);
		}
	}

	@media (max-width: $size-tablet) {
		margin-bottom: 30px;
	}
}

.seasons {
	display: grid;
	grid-gap: 40px;
	grid-template-columns: repeat(4, 1fr);

	@media (max-width: $size-tablet) {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
	}
}

.season {
	background-color: var(--season-color);
	border-radius: 4px;
	box-shadow: 0 4px 6px shadow(shadow, 0.05), 0 5px 20px shadow(shadow, 0.08);
	color: color(white);
	display: block;
	overflow: hidden;
	padding: 14px 18px;
	padding-top: 240px;
	position: relative;
	transition: box-shadow 0.6s ease, opacity 0.3s, transform 0.3s ease-in-out;

	&:hover {
		color: color(white);
		box-shadow: 0 4px 6px shadow(shadow, 0.2), 0 10px 40px shadow(shadow, 0.4);
		transform: scale(1.05);
	}

	&:before {
		background: color(overlay, 0.65);
		content: "";
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 3;
	}

	@media (max-width: $size-tablet) {
		padding-top: 110px;
	}
}

.season-image {
	height: 100%;
	left: 0;
	object-fit: cover;
	object-position: center;
	opacity: 0;
	position: absolute;
	top: 0;
	transition: opacity 300ms;
	width: 100%;
	z-index: 1;

	&.loaded {
		opacity: 1;
	}
}

.name,
.total {
	z-index: 5;
	position: relative;
}

.name {
	font-weight: 700;
	font-size: 1.8rem;
}

.total {
	font-size: 1.4rem;
	opacity: 0.8;
}

@media (max-width: $size-tablet) {
	h2 {
		font-size: 1.7rem;
	}
}
</style>
